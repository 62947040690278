<template>
  <DeferredContent @onLoadComponent="handleFetchAnnouncements">
    <div class="flex flex-col gap-2 bg-white rounded-lg shadow">
      <div class="flex justify-between border-b border-untitled-gray-200 p-2">
        <div class="flex justify-start items-center">
          <h1 class="text-lg font-bold">News Feed</h1>
        </div>
        <div class="flex justify-center items-center">
          <ElButton @click="handleClickViewAll">View All</ElButton>
        </div>
      </div>
      <div
        class="w-full flex flex-col gap-2 max-h-96 overflow-hidden overflow-y-auto p-2 md:px-4 divide-y divide-untitled-gray-200"
      >
        <template v-if="!loadingAnnouncements && announcements.length">
          <div
            class="flex flex-col gap-1 flex-1 cursor-pointer hover:bg-untitled-gray-50 p-2 relative"
            v-for="announcement in announcements"
            :key="announcement"
            @click="handleClickView(announcement.id)"
          >
            <div class="flex flex-1 justify-start items-center gap-2">
              <div class="flex-1 justify-start items-center flex gap-2">
                <div
                  v-if="announcement.is_draft"
                  class="text-xs font-semibold bg-untitled-gray-200 px-2 py-0.5 rounded w-fit h-fit"
                >
                  <p>Draft:</p>
                </div>
                <h1
                  class="line-clamp-2 font-semibold text-untitled-gray-600 text-sm"
                >
                  {{ announcement.title }}
                </h1>
              </div>
              <div class="flex justify-center items-center">
                <p class="text-xs text-untitled-gray-600 font-medium">
                  {{ dateFormat(announcement.created_at) }}
                </p>
              </div>
            </div>
            <div class="flex justify-start items-center">
              <p
                class="line-clamp-3 text-xs font-medium text-untitled-gray-600"
              >
                {{ handleUpdateText(announcement.clean_body) }}
              </p>
            </div>
          </div>
        </template>
        <template v-if="loadingAnnouncements">
          <div
            class="w-full min-h-72 lg:min-h-96 flex justify-center items-center"
          >
            <LoadingSpinner />
          </div>
        </template>

        <template v-if="!loadingAnnouncements && !announcements.length">
          <div
            class="w-full min-h-70 lg:min-h-96 flex text-lg justify-center items-center font-semibold text-untitled-gray-400"
          >
            <h1>No Available Announcements</h1>
          </div>
        </template>
      </div>
    </div>
  </DeferredContent>
</template>

<script setup>
import DeferredContent from "@/components/utilities/DeferredContent.vue";
import LoadingSpinner from "@/components/utilities/LoadingSpinner.vue";
import { dateFormat } from "@/helpers/date_formatter";
import { useAnnouncementsStore } from "@/modules/Announcement/stores/useAnnouncements.store";
import { ElButton } from "element-plus";
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";
import { onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const announcementsStore = useAnnouncementsStore();
const { isHomePage, announcements, loadingAnnouncements } =
  storeToRefs(announcementsStore);

const handleFetchAnnouncements = async () => {
  await announcementsStore.handleFetchAnnouncements();
};

const handleClickView = (announcementId) => {
  router.push({
    name: "Announcement Details",
    params: {
      id: announcementId,
    },
  });
};

const handleClickViewAll = () => {
  router.push({
    name: "Announcement Home Page",
  });
};

const handleUpdateText = (string) => {
  if (!string) {
    return "";
  }

  let newString = string;

  newString = newString.replace(/&amp;/g, "&");
  newString = newString.replace(/&nbsp;/g, " ");

  return newString;
};

onBeforeMount(() => {
  isHomePage.value = true;
});

onMounted(() => {
  //
});
</script>

<style lang="scss" scoped></style>
